import { loadInbentaConf } from './inbenta-conf';
import { locale, translationsPromise } from './locale';

window.addEventListener('DOMContentLoaded', () => void init());

async function init() {
  const translations = await translationsPromise;
  void loadInbentaConf(translations, locale);

  const title = document.getElementById('title');
  if (title) {
    title.innerText = translations['faq_title'];
  }

  const initDomElements = () => {
    const form = document.querySelector('form');
    const input = document.querySelector('input[name=query]');
    if (!form || !input) {
      setTimeout(initDomElements, 1000);
      return;
    }

    form.addEventListener('submit', function () {
      (input as HTMLInputElement).blur();
    });
  };
  initDomElements();
}
