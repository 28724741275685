import {
  SupportedLanguage,
  supportedLanguages,
} from '@pwa/localization/supported-languages';
import { loadLanguage } from '@pwa/translations/src/lib/faq';
import { Locale } from '@wetteronline/pwa-locale';

import { inbentaInstances } from './inbenta-instances';

function determineLocale(): SupportedLanguage {
  const url = new URL(location.href);
  const queryLocale = url.searchParams.get('locale');

  if (queryLocale) {
    if (checkLocaleString(queryLocale)) {
      return checkLocaleString(queryLocale) as SupportedLanguage;
    }
  }

  for (const navigatorLocale of navigator.languages) {
    if (checkLocaleString(navigatorLocale)) {
      return checkLocaleString(navigatorLocale) as SupportedLanguage;
    }
  }

  return 'de';
}

function checkLocaleString(
  localeString: string,
): SupportedLanguage | undefined {
  if (
    (supportedLanguages as string[]).includes(localeString) &&
    localeString in inbentaInstances
  ) {
    return localeString as SupportedLanguage;
  }
  const lang = new Locale(localeString).langWithCharSet;
  if (
    (supportedLanguages as string[]).includes(lang) &&
    lang in inbentaInstances
  ) {
    return lang as SupportedLanguage;
  }
  const langWithout = new Locale(localeString).langWithoutCharSet;
  if (
    (supportedLanguages as string[]).includes(langWithout) &&
    langWithout in inbentaInstances
  ) {
    return langWithout as SupportedLanguage;
  }
}

export const locale = determineLocale();
export const translationsPromise = loadLanguage(locale as string);
